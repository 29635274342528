
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Section, Text, Title } from "../components/Core";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Attributions = (props) => {

  const data = useStaticQuery(graphql`
    query {
      good_vibes_only: file(relativePath: { eq: "good_vibes_only.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      here_there_everywhere: file(relativePath: { eq: "here_there_everywhere.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

  return (
    <>
      <PageWrapper headerDark footerDark>
        <Seo
          title="Opqo | Attributions | Mobile for Maximo"
          pathname={props.location.pathname}
        />
        <PageTitle title="Attributions" titleDark />
        <Section>
          <Container>
            <Row className="justify-content-center pb-5">
              <Col lg="9">
                <Title>
                  Legal Attributions
                </Title>
                <Text>
                  IBM&reg; and Maximo&reg; are trademarks and registered trademarks of International Business Machines Corporation, registered in many jurisdictions worldwide.
                </Text>
                <Text className="pt-3">
                  Red Hat&reg; and OpenShift&reg; are registered trademarks of Red Hat, Inc. or its subsidiaries in the United States and other countries.
                </Text>
                <Text className="pt-3">
                  ArcGIS&reg; is a registered trademark of Environmental Systems Research Institute (ESRI), Inc.
                </Text>
              </Col>
            </Row>
            <Row className="justify-content-center pb-5">
              <Col lg="9">
                <Title>
                  Image Attributions
                </Title>
                <Text>
                  Thank you to the following people for the use of their images.
                </Text>
              </Col>
            </Row>
            <Row className="justify-content-center pb-4">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.good_vibes_only.childImageSharp.fluid} />
                </ContentImg>
                <Text>
                  Photo by <a href="https://unsplash.com/@markadriane?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mark Adriane</a> on <a href="https://unsplash.com/s/photos/idea?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="6" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.here_there_everywhere.childImageSharp.fluid} />
                </ContentImg>
                <Text>
                  Photo by <a href="https://unsplash.com/@jannerboy62?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nick Fewings</a> on <a href="https://unsplash.com/s/photos/directions?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default Attributions;


